import React, { useContext } from "react";
import petsvetIcon from "../assets/Petsvet-icon-eye.png";
import {
  Box,
  IconButton,
  Toolbar,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { SettingsDialog } from "../dialog/SettingsDialog";
import "./Navbar.css";

const pages = [
  "Home",
  "Notfälle",
  "Symptomechecker",
  "Symptome a-z",
  "Krankheiten a-z",
];

const Navbar = () => {
  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);

  return (
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            alignContent: "center",
            gap: "1em",
          }}
        >
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setSettingsDialogOpen(true);
            }}
            aria-label="settings"
            sx={{backgroundColor: 'primary.main', '&:hover': {
      backgroundColor: 'secondary.main',
    },}}
          >
            <SettingsIcon className="settingsIcon" />
          </IconButton>
        </Box>
        <SettingsDialog
          isOpen={settingsDialogOpen}
          onClose={() => {
            setSettingsDialogOpen(false);
          }}
        ></SettingsDialog>
      </Toolbar>
  );
};

export default Navbar;
