import { createTheme } from "@mui/material/styles";
// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0B2D5B",
    },
    secondary: {
      main: "#2ba5fc",
    },
    background: {
      main: "#FFFFFF",
      fallback: "#F5F5F5"
    }
  },
});

export default theme;
