import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Logo from "./components/logo/Logo";
import Body from "./components/body/Body";
import Footer from "./components/Footer";
import React, { useState, useRef, useEffect } from "react";
import { State } from "./State";
import { Box } from "@mui/material";

function App() {
  const [selectedRegions, setSelectedRegions] = useState(new Map());
  const [initialQuestions, setInitialQuestions] = useState({});
  const [extraQuestions, setExtraQuestions] = useState(new Map());
  const [animalType, setAnimalType] = useState();

  const [symptomCandidates, setSymptomCandidates] = useState({});
  const [diseaseCandidates, setDiseaseCandidates] = useState({});
  const [symptom2diseasesLow, setSymptom2diseasesLow] = useState({});
  const [symptom2diseasesMedium, setSymptom2diseasesMedium] = useState({});
  const [symptom2diseasesHigh, setSymptom2diseasesHigh] = useState({});

  const [animalSectionOpened, setAnimalSectionOpened] = useState(true);
  const [animalSectionAnswered, setAnimalSectionAnswered] = useState(false);

  const [regionSectionOpened, setRegionSectionOpened] = useState(false);
  const [regionSectionAnswered, setRegionSectionAnswered] = useState(false);

  const [questionSectionOpen, setQuestionSectionOpen] = useState(false);
  const [questionSectionAnswered, setQuestionSectionAnswered] = useState(false);

  const [topKProbability, setTopKProbability] = useState();

  const [extraQuestionSectionOpen, setExtraQuestionSectionOpen] =
    useState(false);

  const [nextQuestion, setNextQuestion] = useState(null);

  const [extraQuestionsAnswered, setExtraQuestionsAnswered] = useState(false);

  const [summarySectionOpened, setSummarySectionOpened] = useState(false);
  const [summaryUpdateInProgress, setSummaryUpdateInProgress] = useState(false);

  const [startOverSectionOpened, setStartOverSectionOpened] = useState(false);

  // Set up default keys
  if (localStorage.getItem("show_version") === null) {
    localStorage.setItem("show_version", false);
  }

  if (localStorage.getItem("debug") === null) {
    localStorage.setItem("debug", false);
  }

  if (
    localStorage.getItem("early_stopping_threshold") === null ||
    isNaN(parseFloat(localStorage.getItem("early_stopping_threshold")))
  ) {
    localStorage.setItem("early_stopping_threshold", 1);
  }

  if (
    localStorage.getItem("acceptable_disease_likelihood") === null ||
    isNaN(parseFloat(localStorage.getItem("acceptable_disease_likelihood")))
  ) {
    localStorage.setItem("acceptable_disease_likelihood", 0.05);
  }

  if (localStorage.getItem("normalization") === null) {
    localStorage.setItem("normalization", "normal");
  }

  if (
    localStorage.getItem("rounding") === null ||
    (localStorage.getItem("rounding") !== "true" &&
      localStorage.getItem("rounding") !== "false")
  ) {
    localStorage.setItem("rounding", "true");
  }

    const sendSizeToParent = () => {
      if (!mainRef.current) return;

      const width = mainRef.current.offsetWidth;
      const height = mainRef.current.scrollHeight;

      const message = JSON.stringify({ width, height });
      console.log(`sending ${width} : ${height}`)
      window.parent.postMessage(message, "*");
  };

  const mainRef = useRef(null);

  const [settings, setSettings] = useState({
    normalization: localStorage.getItem("normalization"),
    acceptable_disease_likelihood: localStorage.getItem(
      "acceptable_disease_likelihood"
    ),
    round_result_percentage: localStorage.getItem("rounding"),
  });

  useEffect(() => {
    sendSizeToParent(); // Send on mount

    // Observe changes in the DOM
    const resizeObserver = new ResizeObserver(() => {
        sendSizeToParent();
    });

    if (mainRef.current) {
        resizeObserver.observe(mainRef.current);
    }

    return () => {
        resizeObserver.disconnect();
    };
}, []);

  const isDebugMode = () => localStorage.getItem("debug") === "true";

  const resetState = () => {
    setSelectedRegions(new Set());
    setExtraQuestions({});
    setAnimalType(undefined);
    setSymptomCandidates({});
    setDiseaseCandidates({});
    setSymptom2diseasesLow({});
    setSymptom2diseasesMedium({});
    setSymptom2diseasesHigh({});
    setInitialQuestions({});
  };
  

  return (
    <Box ref={mainRef} sx={{ minWidth: "360px" }}>
      <State.Provider
        value={{
          animalType,
          setAnimalType,
          selectedRegions,
          setSelectedRegions,
          initialQuestions,
          setInitialQuestions,
          extraQuestions,
          setExtraQuestions,
          symptomCandidates,
          setSymptomCandidates,
          diseaseCandidates,
          setDiseaseCandidates,
          symptom2diseasesLow,
          setSymptom2diseasesLow,
          symptom2diseasesMedium,
          setSymptom2diseasesMedium,
          symptom2diseasesHigh,
          setSymptom2diseasesHigh,
          resetState,
          isDebugMode,
          animalSectionOpened,
          setAnimalSectionOpened,
          animalSectionAnswered,
          setAnimalSectionAnswered,
          regionSectionOpened,
          setRegionSectionOpened,
          regionSectionAnswered,
          setRegionSectionAnswered,
          questionSectionOpen,
          setQuestionSectionOpen,
          questionSectionAnswered,
          setQuestionSectionAnswered,
          extraQuestionSectionOpen,
          setExtraQuestionSectionOpen,
          extraQuestionsAnswered,
          setExtraQuestionsAnswered,
          nextQuestion,
          setNextQuestion,
          summarySectionOpened,
          setSummarySectionOpened,
          summaryUpdateInProgress,
          setSummaryUpdateInProgress,
          startOverSectionOpened,
          setStartOverSectionOpened,
          topKProbability,
          setTopKProbability,
          settings,
          setSettings,
        }}
      >
        <Navbar />
        {/* <Logo /> */}
        <Body />
      </State.Provider>
      <Footer />
    </Box>
  );
}

export default App;
